<template>
    <vx-card class="card_adjust">
        <h2 class="ml-5"> Aujourd'hui</h2>
        <br/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-sm="12" vs-w="4">
                <div class="container space">
                    <vs-button :color="color.essai" type="filled" size="large" class="w-full" to="admin/booking" title="Essai">{{ essai }}</vs-button> 
                </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-sm="12" vs-w="4">
                <div class="container space">
                    <vs-button :color="color.contractuel" type="filled" size="large" class="w-full" to="admin/booking" title="Contractuelle">{{ contractuel }}</vs-button>
                </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-sm="12" vs-w="4">
                <div class="container space">
                    <vs-button :color="color.actif" type="filled" size="large" class="w-full" to="admin/booking" title="Réservation Membre">{{ member }}</vs-button>
                </div>
            </vs-col>
        </vs-row>
    </vx-card>
</template>

<style lang="scss" scoped>
@media only screen and (min-width: 900px)
{
    .card_adjust{
        margin-right:10px;
    }
}
@media only screen and (max-width: 899px)
{
    .card_adjust{
        margin-left:0px;
    }
    .space{
        padding-bottom:10px;
    }
}
</style>

<script>
import Member from '@/database/models/member'
import Booking from '@/database/models/booking'

export default {
    components:{

	},
    props: {

    },
    data(){
        return{
            color: Member.color,

            tyear : (new Date()).getFullYear(),
            tmonth: (new Date()).getMonth(),
            tday  : (new Date()).getDate(),

            essai      : 0,
            contractuel: 0,
            member     : 0,
        }
    },
    watch:{
        $route (){
            this.update();
        },
    },
    mounted:function()
	{
        this.update();
    },
    methods:{
        update()
        {
            //this.columnChart.series[0].data[2] = 55
            Booking.getTabAllMemory((list)=>
            {
                for( var i=0; i<list.length; i++)
                {
                    var buffer = list[i];
                    var da = new Date(buffer.date)
                    
                    if( da.getFullYear() == this.tyear )
                    if( da.getMonth() == this.tmonth )
                    if( da.getDate() == this.tday )
                    {
                        switch (buffer.type)
                        {
                            case 'e':
                                this.essai++;
                            break;
                            case 'c':
                                this.contractuel++;
                            break;
                            case 's':
                                this.member++;
                            break;
                        }
                    }
                }
            });
        }
    }
}

</script>