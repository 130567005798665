<template>
    <div class="py-1">
        <h2><b>{{ sms_dispo }}</b> SMS disponibles</h2>
        <br/>
        <vs-button v-if="!onLoad" class="btn btn-primary"><a @click="reloadsms">Acheter des SMS</a></vs-button>
        <small v-else><p>Redirection vers page de paiement en cours...</p></small>
    </div>
</template>

<style scoped lang="scss">
a {
    cursor: pointer;
    color: white !important;
}
</style>

<script>


export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            sms_dispo:0,
            uid:'',
            onLoad: false
        }
    },
    watch:{
        $route (){
            this.reload()
        },
    },
    beforeMount:function(){
        this.reload()
    },
    methods:{
        reload()
        {
            this.uid = this.$srvApi.getStudio()

            this.$srvApi.Req('get', 'studio')
            .then(( rep )=>{
                //console.log( rep );
                this.sms_dispo = rep.data[0].sms_max;
            })
        },
        reloadsms() {
            this.onLoad = true
            const url = window.location.hostname
            this.uid = this.$srvApi.getStudio()

            this.$srvApi.Req('get', `smsreload/${url}`)
            .then(( rep )=>{
                if (rep.status == "error") {
                    this.$vs.notify({
                        color:'danger',
                        title:'Une erreur est survenue',
                        text:'Veuillez réessayer plus tard'
                    })
                } else window.location = rep.data._links.checkout.href
            })
            .catch( () => {
                // console.log(error)
                this.$vs.notify({
                    color:'danger',
                    title:'Une erreur est survenue',
                    text:'Veuillez réessayer plus tard'
                })
                this.onLoad = false
            })
        }
    }
}

</script>